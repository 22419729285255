import Request from "src/request";
import { environment } from "src/variables";
import LBA from "src/app/assets/LBA.png";
// Components
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import {
  Card,
  Input,
  Button,
  Select,
  MenuItem,
  CardMedia,
  TextField,
  Typography,
  InputLabel,
  ButtonBase,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Styles
import Header from "src/app/page/Header";
import { Wrapper, ButtonWrapper } from "src/app/App.styles";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const asset: any = {
  fields: {},
  errors: {},
  request: {
    url: "",
    redirect: "",
  },
  render: false,
};

const toastAll: any = toast;


const setFormData = (fields: any) => {
  return Object.keys(fields).map((key) => {
    if (fields[key].type === "sub-form") {
      return {
        id: key,
        label: fields[key].label,
        type: "subForm",
        action: fields[key].action,
        subFields: fields[key],
      };
    }
    return {
      id: key,
      label: fields[key].label,
      value: fields[key].value ? fields[key].value : '',
      type: fields[key].type,
      maxLength: fields[key].maxLength,
      required: fields[key].required,
    };
  });
};

const resetFormData = (fields: any) => {
  return Object.keys(fields).map((key) => {
    if (fields[key].type === "sub-form") {
      return {
        id: key,
        label: fields[key].label,
        type: "subForm",
        action: fields[key].action,
        subFields: fields[key],
      };
    }
    return {
      id: key,
      label: fields[key].label,
      value: "",
      type: fields[key].type,
      maxLength: fields[key].maxLength,
      required: fields[key].required,
    };
  });
};

const setFieldData = (fields: any) => {
  const setFields: any = [];
  Object.keys(fields).map((key) => {
    if (asset.fields[key].type === "sub-form") {
      Object.keys(asset.fields[key]).map((subKey: any) => {
        if (typeof asset.fields[key][subKey] === "string") return;
        setFields[`${key}_${subKey}`] = asset.fields[key][subKey];
        return;
      });
    }
    setFields[key] = asset.fields[key];
  });

  console.log('check');
  Object.keys(setFields).map((id) => {
    const [key, subKey] = id.split("_");
    if (subKey) {
      asset.fields[key][subKey].id = id;
      asset.fields[key][subKey].value = "";
      asset.fields[key][subKey].path = asset.fields[key][subKey].path;
      return;
    }
    asset.fields[key].id = id;
    asset.fields[key].value = (asset.fields[key].value ? asset.fields[key].value : "");
  });

  return fields;
};

const headerText: any = {
  style: {
    fontFamily: "Georgia, serif",
    fontSize: "36px",
  },
};

const toastCenter: any = {
  style: {
    
  }
}

const titleText: any = {
  style: {
    fontFamily: "Georgia, serif",
    fontSize: "26px"
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      width: "-webkit-fill-available",
      alignContent: "center",
      padding: "0px 10px",
      borderRadius: "unset",
    },
    sub: {
      top: "38px",
      width: "87vw",
      maxWidth: "520px",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    payment: {
        maxWidth: 412,
        display: "flex",
        justifyContent: "center",
        background: "#282828"
      },
      method: {
        margin: "5px",
      },
    subHeader: {
      display: "flex",
      justifyContent: "space-between",
      padding: "7px 0px",
    },
    label: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    telLabel: {
      position: "relative",
    },
    telPosition: {
      position: "relative",
      transform: "translate(0, 1.5px) scale(0.75)",
      transformOrigin: "top left",
    },
    toast: {
      top: "50%!important",
      left: "50%!important",
      transform: "translate(-50%, -50%)!important",
      position: "fixed"
    },
    subLabel: {
      position: "relative",
      padding: "10px 0",
      color: "#000000",
      fontFamily: "Georgia, serif",
    },
    selectLabel: {
      paddingBottom: "40px",
    },
    formControl: {
      width: "100%",
      maxHeight: "70px",
      maxWidth: "700px",
      margin: theme.spacing(0.02),
    },
    buttonContainer: {
      minWidth: "100%",
      marginTop: theme.spacing(5),
    },
    spinnerContainer: {
      position: "absolute",
      index: "1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    submitButton: {
      float: "right",
    },
    success: {
      color: "#4caf50",
      position: "relative",
      top: 30,
    },
    noError: {
      padding: "10px",
    },
  })
);

const Contact = (event: any) => {
  const [fields, setFields]: any[] = useState(setFormData(asset.fields));
  const [errors, setErrors]: any = useState(asset.errors);
  const [loading, setLoading]: any = useState(false);
  const [success, setSuccess]: any = useState(false);
  const classes = useStyles();
  const url = environment.apiPath + asset.request.url + environment.source;

  const clearContactForm = () => {
    asset.fields = setFieldData(asset.fields);
    setFields(() => resetFormData(asset.fields));
  };

  const handleSubField = (object: any, key: string) => {
    let str: string = "";
    const subs: any[] = [];
    Object.keys(asset.fields[key]).map((subKey) => {
      if (asset.fields[key][subKey].value) subs.push(asset.fields[key][subKey]);
    });
    subs.forEach((sub) => {
      let value: string = "";
      if (typeof sub.value === "object") value = sub.value.value;
      else value = sub.value;
      str += subs[subs.length - 1].id === sub.id ? value : value + ", ";
    });
    return Object.assign(object, { [key]: str });
  };

  const handleSubError = (newErrors: any, key: string) => {
    Object.keys(asset.fields[key]).map((subKey: any) => {
      if (asset.fields[key][subKey].required && !asset.fields[key][subKey].value) {
        newErrors[`${key}_${subKey}`] = {
          error: true,
          message: `${asset.fields[key][subKey].label} is required!`,
        };
      }
      const poBox = new RegExp("\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b", "i");
      if (subKey === "address" && poBox.test(asset.fields[key][subKey].value.toLowerCase())) {
        newErrors[`${key}_${subKey}`] = {
          error: true,
          message: `${asset.fields[key][subKey].label} can not be a po box!`,
        };
      }
    });
  };

  const fieldChanged = (event: any) => {
    setSuccess(false);

    let newFields = [...fields];
    const value = event.target.value;
    const [key, subKey] = event.target.name.split("_");
    const newField = newFields.reduce((object, newField) => ({ ...object, [newField.id]: newField }), {});

    if (subKey) {
      asset.fields[key][subKey].value = value;
      newField[key].subFields[subKey].value = value;
      labelUpdate(event);
    } else {
      asset.fields[key].value = value;
      newField[key].value = value;
    }

    let company: any = companyLookup(key);

    if (company && key === "phone") {
      Object.keys(company).map((field) => {
        if (typeof company[field] === "object") {
          Object.keys(company[field]).map((subField) => {
            if (!company[field][subField].type) {
              asset.fields[field][subField].value = company[field][subField];
              newField[field].subFields[subField].value = company[field][subField];
            }
            if (asset.fields[field][subField].type === "hidden") {
              asset.fields[field][subField] = company[field][subField];
              newField[field].subFields[subField] = company[field][subField];
            }
          });
        } else {
          if(field === 'label') {
            asset.fields['name'].label = company[field];
            newField['name'].label = company[field];
            return;
          }
          asset.fields[field].value = company[field];
          newField[field].value = company[field];
        }
      });
    }

    if (subKey === "city") {
      company = companyLookup("phone");
      if (company && value.value === "Cane Beds") {
        const subField = "address";
        if (company[key][subField]) asset.fields[key][subField].value = company[key][subField].value;
        if (company[key][subField]) newField[key].subFields[subField].value = company[key][subField].value;
      }
    }

    setFields(newFields);

    let restErrors = { ...errors };
    if (restErrors[key]) restErrors[key] = null;
    if (restErrors[key + "_" + subKey]) restErrors[key + "_" + subKey] = null;
    setErrors(restErrors);
  };

  const companyLookup = (phone: string) => {
    return asset.fields[phone].companyIds
      ? asset.fields[phone].companyIds.reduce(
          (object: any, companyId: string) => ({ ...object, [companyId]: asset.fields["companies"][companyId] }),
          {}
        )[asset.fields[phone].value]
      : null;
  };

  const labelUpdate = (event: any) => {
    const [key] = event.target.name.split("_");
    let subFields: any[] = [];
    subFields = Object.keys(asset.fields[key]).map((subKey) => asset.fields[key][subKey]);
    subFields.map((subField: any) => {
      if (subField.type === "label")
        subField.value = subField.path.reduce((obj: any, name: string) => obj && obj[name], asset.fields);
    });
  };

  const Field = (props: any) => {
    const field = props.field;
    return (
      <React.Fragment key={field.id}>
        <Card className={classes.card}>
          {/* {field.type === "label" ? (
          <FormControl key={field.id} className={classes.formControl}>
            <InputLabel htmlFor={"pickup_city"}>{`${field.label}: ${field.value}`}</InputLabel>
          </FormControl>
        ) : null} */}
          {field.type === "text" ? (
            <FormControl key={field.id} className={classes.formControl} error={!!errors[field.id]}>
              <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
              <Input
                type={field.type}
                id={field.id}
                name={field.id}
                value={field.value}
                onChange={(e: any) => fieldChanged(e)}
                inputProps={{ "aria-label": "description", minLength: field.minLength, maxLength: field.maxLength }}
                aria-describedby={`${field.id}-${field.type}`}
              />
              <FormHelperText id={`${field.id}-${field.type}`} className={!errors[field.id] ? classes.noError : ""}>
                {errors[field.id] ? errors[field.id].message : ""}
              </FormHelperText>
            </FormControl>
          ) : null}
          {field.type === "tel" ? (
            <FormControl key={field.id} className={classes.formControl} error={!!errors[field.id]}>
              <InputLabel htmlFor={field.id} className={field.value ? classes.telPosition : classes.telLabel}>
                {field.label}
              </InputLabel>
              <NumberFormat
                {...props}
                format="+1 (###) ###-####"
                mask="_"
                id={field.id}
                name={field.id}
                type={field.type}
                value={field.value}
                customInput={TextField}
                onChange={(e: any) => fieldChanged(e)}
              />
              <FormHelperText id={`${field.id}-${field.type}`} className={!errors[field.id] ? classes.noError : ""}>
                {errors[field.id] ? errors[field.id].message : ""}
              </FormHelperText>
            </FormControl>
          ) : null}
          {field.type === "select" ? (
            <FormControl
              key={field.id}
              className={`${classes.formControl} ${classes.selectLabel}`}
              error={!!errors[field.id]}
            >
              <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
              <Select
                labelId={`${field.id}-label`}
                id={field.id}
                name={field.id}
                value={field.value}
                label={field.id}
                onChange={(e: any) => {
                  fieldChanged(e);
                }}
              >
                {field.options.map((option: any) => (
                  <MenuItem key={`${field.id}-${option.value}-select`} value={option}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id={`${field.id}-${field.type}`} className={!errors[field.id] ? classes.noError : ""}>
                {errors[field.id] ? errors[field.id].message : ""}
              </FormHelperText>
              {/* The following is for Timmy's website only */}
              <div className={classes.label}>
                <Typography>{`State: ${field.value.state ? field.value.state : ""}`}</Typography>
                <Typography>{`Zip: ${field.value.zip ? field.value.zip : ""}`}</Typography>
              </div>
            </FormControl>
          ) : null}
          {field.type === "text-field" ? (
            <FormControl key={field.id} className={classes.formControl}>
              <TextField
                type={field.type}
                id={field.id}
                name={field.id}
                label={field.label}
                value={field.value}
                multiline
                rows={3}
                onChange={(e: any) => fieldChanged(e)}
                error={!!errors[field.id]}
                aria-describedby={`${field.id}-text-field`}
                inputProps={{
                  maxLength: field.maxLength,
                }}
              />
              <FormHelperText id={`${field.id}-text-field`}>
                {errors[field.id] ? errors[field.id].message : field.maxLength - field.value.length}
              </FormHelperText>
            </FormControl>
          ) : null}
        </Card>
      </React.Fragment>
    );
  };

  const addField = (oldField: any) => {
    const newField = JSON.parse(JSON.stringify(oldField));
    newField.value = "";
    delete newField.action;
    delete newField.subFields.action;
    let index = 1;
    fields.forEach((field: any) => (field.id.includes(newField.id) ? index++ : null));
    newField.id = newField.id + index.toString();
    newField.label = newField.label + " " + index.toString();
    const assetField: any = { id: newField.id, label: newField.label, type: "sub-form", value: "" };
    Object.keys(newField.subFields).map((field) => {
      if (!newField.subFields[field].id) return;
      newField.subFields[field].id = newField.id + "_" + field;
      assetField[field] = newField.subFields[field];
    });
    asset.fields[newField.id] = assetField;
    fields.splice(
      fields.findIndex((field: any) => field.id === oldField.id + (index - 1 > 1 ? (index - 1).toString() : "")) + 1,
      0,
      newField
    );
    const newFields = [...fields];
    console.log("add", asset.fields);
    setFields(newFields);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (loading) return;
    setLoading(true);
    let payload: any = { ...asset.fields };
    delete payload.companies;
    const body = Object.keys(payload).reduce(
      (object: any, key: any) =>
        asset.fields[key].type === "sub-form"
          ? handleSubField(object, key)
          : Object.assign(object, { [key]: asset.fields[key].value }),
      {}
    );
    body.drops = [];
    body.pickups = [];
    fields.forEach((field: any) => {
      if (field.id.includes("drop")) body.drops.push(body[field.id]);
      if (field.id.includes("pickup")) body.pickups.push(body[field.id]);
    });
    let newErrors = { ...errors };
    Object.keys(asset.fields).forEach((key: any) => {
      if (asset.fields[key].type === "sub-form") {
        handleSubError(newErrors, key);
      } else if (asset.fields[key].required && !asset.fields[key].value) {
        newErrors[key] = {
          error: true,
          message: `${asset.fields[key].label} is required!`,
        };
      } else if (key === "phone" && asset.fields[key].value[4] === "1") {
        newErrors[key] = {
          error: true,
          message: `${asset.fields[key].label} area code must start with something other then 1!`,
        };
      } else {
        newErrors[key] = null;
      }
    });
    if (newErrors) setErrors(newErrors);
    asset.errors = errors;

    const hasError = Object.keys(newErrors)
      .map((key) => newErrors[key])
      .some((error) => error);

    if (newErrors && !hasError) setErrors({});

    if (hasError) {
      setLoading(false);
      return;
    }
            

    Request(url, { method: "POST", body: JSON.stringify(body) }).subscribe(
      (result: any) => {
        toastAll.success("Delivery request sent!", {
              position: "top-center",
              autoClose: 5000,  // Auto close after 5 seconds
            });
            setTimeout(() => {
              clearContactForm();
              setLoading(false);
              if(asset.request.redirect) window.location.href = asset.request.redirect;
            }, 5000);
      },
      (error: any) => {
        fields.forEach((field: any) => {
          if(field.id === 'companies') return;
          if (error.errors[field.id]) {
            newErrors[field.id] = {
              error: true,
              message: error.errors[field.id],
            };
          }
        });
        setErrors(newErrors);
        setLoading(false);
      }
    );
  };

  return (
    <React.Fragment>
      <Wrapper><Header text="Delivery Associate" style={titleText.style} /></Wrapper>
      <Wrapper>
        <Card className={classes.payment}>
          <ButtonBase onClick={() => {
              asset.request.redirect = 'https://blue-agave.square.site/';
              const blueAgave = {target: {name: 'phone', value: '+1 (928) 875-2299'}};
              fieldChanged(blueAgave);
            }}>
            <CardMedia
            component="img"
            className={classes.method}
            image={LBA}
            alt="logo blue agave mexican kitchen and auto form fill."
            title="Blue Agave auto fill"
            height="64px"
          />
          </ButtonBase>
        </Card>
      </Wrapper>
      <Wrapper>
          <Header text="Order Form" style={headerText.style} />
      </Wrapper>
      <form id="contact" className={`form ${classes.card}`} onSubmit={handleSubmit}>
          <Wrapper>
            {fields.map((field: any) => {
              if (field.type === "subForm") {
              return (
                <div key={field.id} className={classes.sub}>
                  <div className={classes.subHeader}>
                    <InputLabel className={classes.subLabel} htmlFor={field.id}>
                      {field.label}
                    </InputLabel>
                    {typeof field.action === "object" ? (
                      <Button
                        key={field.action.id}
                        id={field.action.id}
                        name={field.action.id}
                        type={field.action.type}
                        variant="outlined"
                        color="default"
                        onClick={() => addField(field)}
                      >
                        {field.action.label}
                      </Button>
                    ) : null}
                  </div>
                  {Object.keys(field.subFields).map((key: any) => {
                    if (typeof field.subFields[key] === "object") return Field({ field: field.subFields[key] });
                  })}
                </div>
              );
            } else {
              return Field({ field: field });
            }
          })}
          <ToastContainer className={classes.toast}/>
          <ButtonWrapper className={classes.buttonContainer}>
            {success && <Typography className={classes.success}>{success}</Typography>}
            <Button variant="contained" color="secondary" type="submit" className={classes.submitButton}>
              {loading && <CircularProgress size={36} className={classes.spinnerContainer} color="primary" />}
              Submit
            </Button>
          </ButtonWrapper>
        </Wrapper>
      </form>
    </React.Fragment>
  );
};

export default function Form(props: any) {
  asset.request.url = props.urlRequest;
  asset.fields = props.formFields;
  asset.fields = setFieldData(asset.fields);
  return (
    <div>
      <Contact />
    </div>
  );
}
